import { useClaim } from "../api";
import { ApprovalForm, SpinnerFullscreen } from "../components";
import { Helmet } from "react-helmet";
import i18n from "i18n-js";
import { Fragment } from "react";
import { useParams } from "react-router";

export const ApprovalPage = () => {
  const { id } = useParams<{ id: string }>();
  const { claim, isError, isLoading, update, approve } = useClaim(id);

  if (isError || !claim) return <SpinnerFullscreen />;

  if (isLoading) return <SpinnerFullscreen />;

  return (
    <Fragment>
      <Helmet>
        <title>
          {i18n.t("settings.title")} - {i18n.t("approval.title")}
        </title>
      </Helmet>
      <ApprovalForm claim={claim} onEditForm={update} onApprove={approve} />
    </Fragment>
  );
};
