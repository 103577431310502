import { Box, Heading } from "@chakra-ui/layout";
import { Table, Tbody, Td, Tfoot, Th, Thead, Tr } from "@chakra-ui/table";
import { FC } from "react";
import { useBreakpointValue } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { NumberField } from "./number-input";
import i18n from "i18n-js";

interface InvoiceTableProps {
  isLocked?: boolean;
}

export const InvoiceTable: FC<InvoiceTableProps> = ({ isLocked = false }) => {
  const { watch } = useFormContext();
  const tableSize = useBreakpointValue({ base: "sm", sm: "sm", md: "md" });
  const total = parseFloat(watch("total"));
  const iva = parseInt(watch("iva"));
  const ivaPerc = iva / 100;
  const amountOrZero = total || 0;
  const absIVA = (amountOrZero / (1 + ivaPerc)) * ivaPerc;
  const calcTaxBase = amountOrZero - absIVA;

  const format = (number: number) =>
    number.toLocaleString("es-ES", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  return (
    <Box marginTop={16} marginBottom={16}>
      <Heading size="md">{i18n.t("approval.items")}</Heading>
      <Box overflowX="auto" width={["calc(100vw - 32px)", "100%", "100%"]}>
        <Table variant="simple" marginTop={8} size={tableSize}>
          <Thead>
            <Tr>
              <Th isNumeric>{i18n.t("approval.quantity")}</Th>
              <Th>{i18n.t("approval.description")}</Th>
              <Th isNumeric>{i18n.t("approval.unitPrice")}</Th>
              <Th isNumeric>{i18n.t("approval.amount")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td isNumeric>1</Td>
              <Td>{i18n.t("approval.foodAndDrinks")}</Td>
              <Td isNumeric display="flex" justifyContent="flex-end">
                <NumberField
                  name="total"
                  isLocked={isLocked}
                  prefix="€"
                  precision={2}
                />
              </Td>
              <Td isNumeric>€{format(amountOrZero)}</Td>
            </Tr>
          </Tbody>
          <Tfoot>
            <Tr>
              <Td></Td>
              <Td>{i18n.t("approval.taxBase")}</Td>
              <Td></Td>
              <Td isNumeric>€{format(calcTaxBase)}</Td>
            </Tr>
            <Tr>
              <Td></Td>
              <Td>{i18n.t("approval.iva")}</Td>
              <Td isNumeric display="flex" justifyContent="flex-end">
                <NumberField name="iva" isLocked={isLocked} suffix="%" />
              </Td>
              <Td isNumeric>€{format(absIVA)}</Td>
            </Tr>
            <Tr fontWeight="bold">
              <Td></Td>
              <Td>{i18n.t("approval.total")}</Td>
              <Td></Td>
              <Td isNumeric>€{format(amountOrZero)}</Td>
            </Tr>
          </Tfoot>
        </Table>
      </Box>
    </Box>
  );
};
