import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  Fragment,
  ReactNode,
  FC,
} from "react";
import { locales, localeFromNavigator } from "./";
import i18n from "i18n-js";
import { Helmet } from "react-helmet";
import { useLocalStorage } from "../hooks/local-storage";

interface LocaleProps {
  code: string;
  name: string;
}

interface InitContextProps {
  locale: LocaleProps;
  setLocale: Dispatch<SetStateAction<LocaleProps>>;
}

export const LocaleContext = createContext({} as InitContextProps);

export const useLocale = () => useContext(LocaleContext);

export const LocaleAware: FC = ({ children }) => {
  const { locale } = useLocale();
  i18n.locale = locale.code;

  return (
    <Fragment key={locale.code}>
      <Helmet>
        <html lang={locale.code} />
      </Helmet>
      {children}
    </Fragment>
  );
};

interface LocaleContextProviderProps {
  locale?: string;
  children: ReactNode;
}

const LocaleContextProvider: FC<LocaleContextProviderProps> = ({
  locale,
  children,
}) => {
  const initial =
    locales.find((x) => x.code === (locale || localeFromNavigator())) ||
    locales[0];

  const [defaultLocale, setLocale] = useLocalStorage<any>("locale", initial);

  const value = { locale: defaultLocale, setLocale };

  return (
    <LocaleContext.Provider value={value}>
      <LocaleAware>{children}</LocaleAware>
    </LocaleContext.Provider>
  );
};

export default LocaleContextProvider;
