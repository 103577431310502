import i18n from "i18n-js";

i18n.defaultLocale = "es";

export interface Map {
  [key: string]: Map | any;
}

const formatTranslationsWithLocales = (locales: any[], translations: Map) =>
  locales.reduce((memo: Map, locale) => {
    memo[locale.code] = {};
    Object.entries(translations).forEach(([sectionName, sectionContents]) => {
      memo[locale.code][sectionName] = sectionContents[locale.code];
    });
    return memo;
  }, {});

export const localeFromNavigator = () => {
  const navigatorLangMatches = navigator.language.match(/^[a-z|A-Z]+/);

  if (
    navigatorLangMatches &&
    locales.map((x) => x.code).includes(navigatorLangMatches[0])
  ) {
    return navigatorLangMatches[0];
  } else {
    return i18n.defaultLocale;
  }
};

export const locales = [
  { code: "en", name: "English" },
  { code: "es", name: "Castellano" },
  { code: "ca", name: "Català" },
];

const translations = {
  home: require("./home.page"),
  approval: require("./approval.page"),
  settings: require("./settings"),
};

i18n.translations = formatTranslationsWithLocales(locales, translations);
i18n.locale = localeFromNavigator();
i18n.fallbacks = true;
