import { Input } from "@chakra-ui/input";
import { Flex, Text } from "@chakra-ui/layout";
import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputStepper,
} from "@chakra-ui/number-input";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface NumberFieldProps {
  isLocked: boolean;
  name: string;
  prefix?: string;
  suffix?: string;
  precision?: number;
}

export const NumberField: FC<NumberFieldProps> = ({
  isLocked,
  name,
  prefix,
  suffix,
  precision = 0,
}) => {
  const { control } = useFormContext();
  const step = (decimals: number) => 1 / 10 ** decimals;

  return (
    <Controller
      control={control}
      name={name}
      render={({ name, ref, value, onBlur, onChange }) => (
        <NumberInput
          onBlur={onBlur}
          ref={ref}
          value={value}
          onChange={onChange}
          width={24}
          step={step(precision)}
          precision={precision}
          isReadOnly={isLocked}
          size="md"
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            width={4}
            position="absolute"
            height="100%"
          >
            <Text>{prefix}</Text>
          </Flex>
          <Input
            value={value}
            type="number"
            inputMode="decimal"
            step={step(precision)}
            onChange={onChange}
            name={name}
          />
          <Flex
            alignItems="center"
            justifyContent="center"
            width={4}
            position="absolute"
            marginRight={8}
            right={0}
            top={0}
            height="100%"
          >
            <Text>{suffix}</Text>
          </Flex>
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      )}
    />
  );
};
