import { FC } from "react";
import i18n from "i18n-js";
import { Lang } from "./";

export const Nav: FC = () => {
  return (
    <nav
      className="relative flex items-center justify-between sm:h-10 md:justify-center"
      aria-label="Global"
    >
      <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
        <div className="flex items-center justify-between w-full md:w-auto">
          <a href="/">
            <span className="sr-only">{i18n.t("settings.title")}</span>
            <img
              className="h-8 w-auto sm:h-10"
              src={i18n.t("settings.asset_logo")}
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="absolute flex items-center justify-end inset-y-0 right-0 text-xs md:text-base">
        <Lang />
      </div>
    </nav>
  );
};
