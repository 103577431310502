import { Helmet } from "react-helmet";
import HomePagePreview from "../previews/home.page.preview";
import i18n from "i18n-js";
import { FC } from "react";

export const AdminPage: FC = () => {
  const CMS = require("netlify-cms-app");
  CMS.init({
    config: {
      display_url: process.env.REACT_APP_URL,
      local_backend: process.env.REACT_APP_CMS_LOCAL_BACKEND === "true",
    },
  });
  CMS.registerPreviewStyle("/tailwind.css");
  CMS.registerPreviewTemplate("home", HomePagePreview);
  CMS.registerPreviewTemplate("settings", HomePagePreview);
  return (
    <Helmet>
      <html lang="en" />
      <title>{i18n.t("settings.title")} - Admin</title>
    </Helmet>
  );
};
