import { FC, useEffect, useState } from "react";
import {
  Badge,
  Box,
  Divider,
  Flex,
  Stack,
  Text,
  useToast,
  VStack,
  Image,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { ClaimApiResponse } from "../api";
import { BillerDetails, CompanyDetails, InvoiceTable } from "../components";
import { UpdateClaimDto } from "../dtos";
import { ClaimStatus } from "../domain";
import { Label } from "./label";
import { ActionsBar } from "./actions-bar";
import i18n from "i18n-js";
import ReactGA from "react-ga";

interface ApprovalFormProps {
  claim: ClaimApiResponse;
  onEditForm: (updateClaimDto: UpdateClaimDto) => Promise<void>;
  onApprove: () => Promise<void>;
}

interface FormState {
  name: string;
  legalId: string;
  address: string;
  total: string;
  iva: string;
}

export const ApprovalForm: FC<ApprovalFormProps> = ({
  claim,
  onEditForm,
  onApprove,
}) => {
  const { company, restaurant, receipt, status } = claim;
  const [isApproving, setIsApproving] = useState(false);
  const toast = useToast();

  const claimIsApproved = status === ClaimStatus.RESOLVED;

  const methods = useForm<FormState>({
    defaultValues: {
      name: restaurant.name,
      legalId: restaurant.legalId,
      address: restaurant.address,
      total: receipt.total.toFixed(2),
      iva: receipt.iva.toFixed(0),
    },
  });

  const submittable =
    methods.formState.isDirty &&
    !methods.formState.isSubmitting &&
    !claimIsApproved;

  const onSubmit = async ({
    name,
    legalId,
    address,
    total,
    iva,
  }: FormState) => {
    const dto: UpdateClaimDto = {
      restaurant: { ...restaurant, name, legalId, address },
      receipt: { ...receipt, total: parseFloat(total), iva: parseInt(iva) },
    };

    try {
      await onEditForm(dto);
      toast({
        title: i18n.t("approval.alerts.changesSaved.title"),
        description: i18n.t("approval.alerts.changesSaved.description"),
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: i18n.t("approval.alerts.changesSavedError.title"),
        description: i18n.t("approval.alerts.changesSavedError.description"),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleApprove = async () => {
    setIsApproving(true);
    try {
      await onApprove();
      setIsApproving(false);
      toast({
        title: i18n.t("approval.alerts.invoiceApproved.title"),
        description: i18n.t("approval.alerts.invoiceApproved.description"),
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      ReactGA.event({
        category: "Website",
        action: "Approve Claim",
        value: 1,
      });
      return;
    } catch (err) {
      setIsApproving(false);
      toast({
        title: i18n.t("approval.alerts.invoiceApprovedError.title"),
        description: i18n.t("approval.alerts.invoiceApprovedError.description"),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      ReactGA.event({
        category: "Website",
        action: "Approve Claim",
        value: 0,
      });
    }
  };

  const { reset } = methods;

  useEffect(() => {
    reset({
      name: restaurant.name,
      legalId: restaurant.legalId,
      address: restaurant.address,
      total: receipt.total.toFixed(2),
      iva: receipt.iva.toFixed(0),
    });
  }, [restaurant, receipt, reset]);

  return (
    <FormProvider {...methods}>
      <Flex
        as="form"
        onSubmit={methods.handleSubmit(onSubmit)}
        justifyContent="center"
        alignItems="flex-start"
        paddingY={10}
        backgroundColor="gray.100"
        minHeight="100vh"
      >
        <Box
          flex={1}
          boxShadow="md"
          borderRadius="lg"
          paddingY={[16, 24]}
          paddingX={[4, 4, 32]}
          backgroundColor="white"
          maxWidth="5xl"
        >
          <Stack
            direction={["column", "row"]}
            spacing={[8, 0]}
            justifyContent="space-between"
          >
            <VStack alignItems="flex-start">
              <Label content={i18n.t("approval.date")} />
              <Text marginTop={0} paddingBottom={2}>
                {new Date(receipt.date).toLocaleDateString()}
              </Text>
            </VStack>
            <VStack alignItems="flex-start">
              <Label content={i18n.t("approval.status")} />
              <Text marginTop={0} paddingBottom={2}>
                <Badge
                  fontSize="sm"
                  colorScheme={claimIsApproved ? "green" : "orange"}
                >
                  {claimIsApproved
                    ? i18n.t("approval.approved")
                    : i18n.t("approval.awaitsApproval")}
                </Badge>
              </Text>
            </VStack>
          </Stack>
          <Divider marginY={8} />
          <Stack
            direction={["column", "column", "row"]}
            alignItems="flex-start"
            spacing={16}
          >
            <BillerDetails isLocked={claimIsApproved} />
            <CompanyDetails
              name={company.name}
              legalId={company.legalId}
              address={company.address}
            />
          </Stack>
          <InvoiceTable isLocked={claimIsApproved} />
          <Image src={receipt.imageUrl} paddingX={[8, 16]} />
        </Box>
        {!claimIsApproved && (
          <ActionsBar
            isSubmitting={methods.formState.isSubmitting}
            isApproving={isApproving}
            submittable={submittable}
            approvable={!methods.formState.isDirty}
            onApproval={handleApprove}
          />
        )}
      </Flex>
    </FormProvider>
  );
};
