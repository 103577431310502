import { Helmet } from "react-helmet";
import i18n from "i18n-js";
import {
  Hero,
  Pricing,
  CompanyFeatures,
  UserFeatures,
  Companies,
  Panel,
  Contact,
  Newsletter,
  Layout,
} from "../components";
import { FC } from "react";

export const HomePage: FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>
          {i18n.t("settings.title")} - {i18n.t("home.title")}
        </title>
      </Helmet>
      <Hero show={i18n.t("home.hero.visible")} />
      <Panel show={i18n.t("home.panel.visible")} />
      <Companies show={i18n.t("home.companies.visible")} />
      <CompanyFeatures show={i18n.t("home.company_features.visible")} />
      <UserFeatures show={i18n.t("home.user_features.visible")} />
      <Contact show={i18n.t("home.contact.visible")} />
      <Pricing show={i18n.t("home.pricing.visible")} />
      <Newsletter show={i18n.t("home.newsletter.visible")} />
    </Layout>
  );
};
