import { ChakraProvider } from "@chakra-ui/react";
import { Route, Switch, Router } from "react-router-dom";
import { HomePage } from "./pages/home.page";
import { ApprovalPage } from "./pages/approval.page";
import { AdminPage } from "./pages/admin.page";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import LocaleContextProvider from "./i18n/locale-context";
import { Head } from "./components";

const history = createBrowserHistory();

history.listen((location) => {
  ReactGA.pageview(location.pathname + location.search);
});

export const App = () => {
  return (
    <LocaleContextProvider>
      <Head>
        <Router history={history}>
          <Switch>
            <Route exact path="/admin" component={AdminPage} />
            <Route exact path="/" component={HomePage} />
            <ChakraProvider>
              <Route path="/approvals/:id" component={ApprovalPage} />
            </ChakraProvider>
          </Switch>
        </Router>
      </Head>
    </LocaleContextProvider>
  );
};
