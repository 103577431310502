import { useState } from "react";

export const useLocalStorage = <T>(key: string, initial: T) => {
  const [val, setVal] = useState<T>(() => {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initial;
  });

  const setValue = (value: T | ((val: T) => T)) => {
    const valueToStore = value instanceof Function ? value(val) : value;
    setVal(valueToStore);
    window.localStorage.setItem(key, JSON.stringify(valueToStore));
  };

  return [val, setValue] as const;
};
