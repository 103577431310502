import { ComponentType, FC } from "react";

interface Props {
  show?: boolean | string;
}

const withConditionalRendering = <T extends Props>(
  Comp: ComponentType<T>
): FC<T> => {
  return ({ show, ...props }: Props) => {
    return show ? <Comp {...(props as T)} /> : null;
  };
};

export default withConditionalRendering;
