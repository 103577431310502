import i18n from "i18n-js";
import withConditionalRendering from "./with-conditional-rendering";

export const UserFeatures = withConditionalRendering(() => {
  return (
    <div className="bg-indigo-700">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white tracking-tight">
          {i18n.t("home.user_features.heading")}
        </h2>
        <p className="mt-4 max-w-3xl text-lg text-indigo-200">
          {i18n.t("home.user_features.description")}
        </p>
        <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-3 lg:mt-16 lg:gap-x-8 lg:gap-y-16">
          <div>
            <div>
              <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                <img
                  className="h-6 w-6 text-white"
                  src={i18n.t(
                    "home.user_features.features.feature1.asset_icon"
                  )}
                  alt=""
                />
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-lg font-medium text-white">
                {i18n.t("home.user_features.features.feature1.heading")}
              </h3>
              <p className="mt-2 text-base text-indigo-200">
                {i18n.t("home.user_features.features.feature1.description")}
              </p>
            </div>
          </div>
          <div>
            <div>
              <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                <img
                  className="h-6 w-6 text-white"
                  src={i18n.t(
                    "home.user_features.features.feature2.asset_icon"
                  )}
                  alt=""
                />
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-lg font-medium text-white">
                {i18n.t("home.user_features.features.feature2.heading")}
              </h3>
              <p className="mt-2 text-base text-indigo-200">
                {i18n.t("home.user_features.features.feature2.description")}
              </p>
            </div>
          </div>
          <div>
            <div>
              <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                <img
                  className="h-6 w-6 text-white"
                  src={i18n.t(
                    "home.user_features.features.feature3.asset_icon"
                  )}
                  alt=""
                />
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-lg font-medium text-white">
                {i18n.t("home.user_features.features.feature3.heading")}
              </h3>
              <p className="mt-2 text-base text-indigo-200">
                {i18n.t("home.user_features.features.feature3.description")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
