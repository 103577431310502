import { HomePage } from "../pages/home.page";
import LocaleContextProvider from "../i18n/locale-context";
import { updateI18nDataFromCms } from "../i18n/cms-preview";
import { Router } from "react-router-dom";
import { createMemoryHistory } from "history";
import { FC } from "react";

export const history = createMemoryHistory({ initialEntries: ["/"] });

const HomePagePreview: FC = ({ entry, getAsset }: any) => {
  const loaded = updateI18nDataFromCms(entry, getAsset);
  if (loaded) {
    return (
      <LocaleContextProvider>
        <Router history={history}>
          <HomePage />
        </Router>
      </LocaleContextProvider>
    );
  } else {
    return <div>Loading...</div>;
  }
};

export default HomePagePreview;
