import { FC } from "react";
import { Footer, Nav } from "./";
import "../index.css";

export const Layout: FC = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen whitespace-pre-wrap">
      <div className="flex-auto">
        <div className="absolute z-10 w-full">
          <div className="relative mx-auto px-4 sm:px-6 mt-6 max-w-7xl">
            <Nav />
          </div>
        </div>
        {children}
      </div>
      <div className="flex-shrink-0">
        <Footer />
      </div>
    </div>
  );
};
