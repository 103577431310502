import useSwr from "swr";
import { Config } from "../config";
import { ClaimStatus, Company, Receipt, Restaurant } from "../domain";
import { UpdateClaimDto } from "../dtos";
import { fetcher } from "./fetcher";

export interface ClaimApiResponse {
  id: number;
  shortId: string;
  status: ClaimStatus;
  company: Company;
  receipt: Receipt;
  restaurant: Restaurant;
}

export const useClaim = (id: string) => {
  const { data, error, mutate } = useSwr<ClaimApiResponse, any>(
    `${Config.API_BASE_URL}/claims/${id}`,
    fetcher
  );

  const update = async ({ restaurant, receipt }: UpdateClaimDto) => {
    const body = JSON.stringify({
      restaurant,
      receipt,
    });

    const result = await fetcher(`${Config.API_BASE_URL}/claims/${id}`, {
      method: "PATCH",
      body,
    });

    mutate(result);

    return result;
  };

  const approve = async () => {
    const result = await fetcher(
      `${Config.API_BASE_URL}/claims/${id}/approve`,
      {
        method: "POST",
      }
    );

    mutate(result);

    return result;
  };

  return {
    claim: data,
    isLoading: !error && !data,
    isError: error,
    update,
    approve,
  };
};
