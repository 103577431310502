import { locales, Map as LocalMap } from "./index";
import { Map } from "immutable";
import i18n from "i18n-js";

export const updateI18nDataFromCms = (
  entry: Map<string, any>,
  getAsset: ((arg0: any) => any) | undefined
): boolean => {
  // data contains the entry data for the default locale (that is the first in the admin/config.yml->i18n.locales array)
  const data = getAllAssets(entry.getIn(["data"]).toJS(), getAsset);

  const collection = entry.getIn(["slug"]).toString();

  // i18nData contains the other translations nested into an object with the lang code as key
  const i18nData = getAllAssets(entry.getIn(["i18n"]).toJS(), getAsset);

  // find out the current locale looking at which one is not in the i18nData
  const currentLocale = locales
    .map((y) => y.code)
    .find((x) => !Object.keys(i18nData).includes(x));

  if (data && i18nData && currentLocale) {
    // @ts-ignore
    i18n.translations[currentLocale][collection] = data;

    Object.entries(i18nData).forEach(([locale, content]) => {
      // @ts-ignore
      i18n.translations[locale][collection] = (content as LocalMap).data;
    });

    return true;
  }
  return false;
};

const getAllAssets = (
  data: LocalMap | string,
  getAsset: any
): LocalMap | string => {
  if (!data) return data;
  for (let [key, value] of Object.entries(data)) {
    if (
      !(typeof value === "string") &&
      !(value instanceof String) &&
      !Array.isArray(value)
    ) {
      // @ts-ignore
      data[key] = getAllAssets(value, getAsset);
    } else if (key.startsWith("asset_")) {
      if (Array.isArray(value)) {
        console.log(value);
        // @ts-ignore
        data[key] = value.map((x) => getAsset(x));
      } else {
        // @ts-ignore
        data[key] = getAsset(value).url;
      }
    }
  }

  return data;
};
