import i18n from "i18n-js";

export const Footer = () => {
  return (
    <footer className="bg-gray-800" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <img
              className="h-10 filter grayscale brightness-200 contrast-200"
              src={i18n.t("settings.asset_logo")}
              alt={i18n.t("settings.title")}
            />
            <p className="text-gray-500 text-base">
              {i18n.t("home.footer.text")}
            </p>
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8">
          <p className="text-base text-gray-400 xl:text-center">
            {i18n.t("home.footer.copyright")}
          </p>
        </div>
      </div>
    </footer>
  );
};
