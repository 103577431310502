import { Heading } from "@chakra-ui/layout";
import { HeadingProps } from "@chakra-ui/react";
import { FC } from "react";

interface LabelProps extends HeadingProps {
  content: string;
}

export const Label: FC<LabelProps> = ({ content, ...rest }) => (
  <Heading
    textTransform="uppercase"
    fontSize="xs"
    color="gray.600"
    letterSpacing="wider"
    {...rest}
  >
    {content}
  </Heading>
);
