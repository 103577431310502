import { Button } from "@chakra-ui/button";
import { Stack } from "@chakra-ui/layout";
import { useBreakpointValue } from "@chakra-ui/media-query";
import { FC } from "react";
import i18n from "i18n-js";

interface ActionsBarProps {
  submittable?: boolean;
  isSubmitting?: boolean;
  isApproving?: boolean;
  approvable?: boolean;
  onApproval(): void;
}

export const ActionsBar: FC<ActionsBarProps> = ({
  submittable = false,
  isSubmitting = false,
  isApproving = false,
  onApproval,
  approvable = false,
}) => {
  const buttonSize = useBreakpointValue({ base: "md", sm: "md", md: "lg" });
  return (
    <Stack
      direction={["column", "row"]}
      spacing={[6, 10]}
      position="fixed"
      bottom={[4, 8, 16]}
    >
      <Button
        colorScheme="blue"
        size={buttonSize}
        boxShadow="lg"
        type="submit"
        disabled={!submittable}
        isLoading={isSubmitting}
      >
        {approvable
          ? i18n.t("approval.allChangesSaved")
          : i18n.t("approval.saveChanges")}
      </Button>
      <Button
        colorScheme="green"
        size={buttonSize}
        boxShadow="lg"
        disabled={!approvable}
        isLoading={isApproving}
        onClick={onApproval}
      >
        {i18n.t("approval.approve")}
      </Button>
    </Stack>
  );
};
