import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Box, Heading, VStack } from "@chakra-ui/layout";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import i18n from "i18n-js";
import { Textarea } from "@chakra-ui/textarea";

interface BillerDetailsProps {
  isLocked?: boolean;
}

export const BillerDetails: FC<BillerDetailsProps> = ({ isLocked = false }) => {
  const { register } = useFormContext();

  return (
    <Box flex={1}>
      <Heading size="md">{i18n.t("approval.yourDetails")}</Heading>
      <VStack spacing={5} marginTop={8} alignItems="flex-start">
        <FormControl id="name">
          <FormLabel
            textTransform="uppercase"
            fontSize="xs"
            lineHeight={1}
            color="gray.600"
            fontWeight="bold"
            letterSpacing="wider"
            marginBottom={2}
          >
            {i18n.t("approval.businessName")}
          </FormLabel>
          <Textarea name="name" ref={register} isReadOnly={isLocked} />
        </FormControl>
        <FormControl id="legalId">
          <FormLabel
            textTransform="uppercase"
            fontSize="xs"
            color="gray.600"
            lineHeight={1}
            fontWeight="bold"
            letterSpacing="wider"
            marginBottom={2}
          >
            {i18n.t("approval.legalId")}
          </FormLabel>
          <Input name="legalId" ref={register} isReadOnly={isLocked} />
        </FormControl>
        <FormControl id="address">
          <FormLabel
            textTransform="uppercase"
            fontSize="xs"
            color="gray.600"
            lineHeight={1}
            fontWeight="bold"
            letterSpacing="wider"
            marginBottom={2}
          >
            {i18n.t("approval.address")}
          </FormLabel>
          <Textarea name="address" ref={register} isReadOnly={isLocked} />
        </FormControl>
      </VStack>
    </Box>
  );
};
