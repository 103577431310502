import i18n from "i18n-js";
import withConditionalRendering from "./with-conditional-rendering";

export const Companies = withConditionalRendering(() => {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div>
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              {i18n.t("home.companies.heading")}
            </h2>
            <p className="mt-3 max-w-3xl text-lg text-gray-500">
              {i18n.t("home.companies.description")}
            </p>
          </div>
          <div className="mt-8 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
            {((i18n.t("home.companies.companies") as unknown) as string[]).map(
              (image: string) => (
                <div
                  key={image}
                  className="col-span-1 flex justify-center py-8 px-8 bg-gray-50"
                >
                  <img
                    className="max-h-12 filter grayscale"
                    src={image}
                    alt=""
                  />
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
