import { Box, Heading, Text, VStack } from "@chakra-ui/layout";
import { FC } from "react";
import { Label } from "./label";
import i18n from "i18n-js";

interface CompanyDetailsProps {
  name: string;
  legalId: string;
  address: string;
}

export const CompanyDetails: FC<CompanyDetailsProps> = ({
  name,
  legalId,
  address,
}) => {
  return (
    <Box flex={1}>
      <Heading size="md">{i18n.t("approval.billTo")}</Heading>
      <VStack spacing={5} marginTop={8} alignItems="flex-start">
        <VStack alignItems="flex-start">
          <Label content={"-"} color={"transparent"} />
          <Text marginTop={0} paddingBottom={2}>
            {name}
          </Text>
        </VStack>
        <VStack alignItems="flex-start">
          <Label content={i18n.t("approval.legalId")} />
          <Text marginTop={0} paddingBottom={2}>
            {legalId}
          </Text>
        </VStack>
        <VStack alignItems="flex-start">
          <Label content={i18n.t("approval.address")} />
          <Text marginTop={0} paddingBottom={2}>
            {address}
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
};
